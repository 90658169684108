
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth"
const firebaseConfig = {
    apiKey: "AIzaSyAKQEOqLoP1F5_TdsxRMtI5snxxnhXfXq8",
    authDomain: "expense-b0293.firebaseapp.com",
    projectId: "expense-b0293",
    storageBucket: "expense-b0293.appspot.com",
    messagingSenderId: "283259388349",
    appId: "1:283259388349:web:08c26a42e2e3c90d7992d4",
    measurementId: "G-LT3KQY6VY2"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
export {auth,provider};